import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Landing.css";
import demoUi from '../Assets/demo-ui.png';

function LandingPage() {
  const navigate = useNavigate();
  const [error, setError] = useState(false);

  // State to control display of the modal and acceptance of terms
  const [showModal, setShowModal] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  // Show modal from anywhere (including the footer link)
  const handleShowModal = () => {
    setShowModal(true);
  };

  function spotifyAuth() {
    const requestOptions = {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/auth/spotify`, requestOptions)
      .then((res) => {
        if (!res.ok && res.status === 400) {
          return res.json().then((data) => {
            setError(data.error);
          });
        }
        return res.json();
      })
      .then((data) => {
        console.log(data);
        (data.spotifyAuthorized && data.authenticated)
          ? navigate("/create_event")
          : window.location.replace(data.spotifyAuthLink);
      });
  }

  function handleCreateEventClick() {
    if (!termsAccepted && !localStorage.getItem("tracktap_termsAccepted")) {
      setShowModal(true);
    } else {
      spotifyAuth();
    }
  }

  function logout() {
    const requestOptions = {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/sign_out`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        window.location.reload();
      });
  }

  // Simple Footer for your page
  // We pass the handleShowModal callback from LandingPage as onShowModal prop
  const Footer = ({ onShowModal }) => {
    const currentYear = new Date().getFullYear();

    return (
      <footer style={{ textAlign: "center", padding: "10px", fontSize: "14px", paddingBottom: "30px"}}>
        <p>© {currentYear} Robert Sheiman. All rights reserved.</p>
        <p style={{ color: "#888" }}>
          This product is currently in beta. To gain access, please send the email address associated
          with your Spotify account to <a style={{ color: "#888" }} href="mailto:sheiman.robert.18@gmail.com">sheiman.robert.18@gmail.com</a>
        </p>
        <p style={{ color: "#888" }}>
          {/* Link that triggers the Terms of Service & Privacy Policy modal */}
          <a
            style={{ color: "#888", cursor: "pointer", textDecoration: "underline" }}
            onClick={onShowModal}
          >
            View Terms of Service &amp; Privacy Policy
          </a>
        </p>
      </footer>
    );
  };

  const overlayStyles = {
      position: "fixed",
      top: 0, left: 0, right: 0, bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 999
    };
    const modalStyles = {
      background: "#c8c8dc",
      padding: "10px",
      borderRadius: "8px",
      maxWidth: "600px",
      width: "90%",
      maxHeight: "80vh",
      overflowY: "auto",
      boxShadow: "0 2px 8px rgba(0,0,0,0.3)"
    };

  return (
    <React.Fragment>
      <div className="landing__viewport">
        <div className="landing__container">
          <h2 className="title">TrackTap.</h2>

          <div className="landing__header--container">
            <div className="landing__header--left">
              <h1>Pick the Tunes, Set the Mood. Together.</h1>
              <p>✅ Select a playlist from Spotify.</p>
              <p>🗳️ Vote on songs to craft a queue, together.</p>
              <p>🎛️ Control the music at your hangout or gathering.</p>
            </div>
            <div className="landing__header--right">
              <div className="phone-frame">
                <div className="phone-top"></div> {/* Simulates phone speaker area */}
                <img src={demoUi} alt="landing" className="ui-screenshot" />
              </div>
            </div>
          </div>

          <form
            className="queueForm"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            {error && <p>Please enter an event code.</p>}
            <input type="text" id="event_code_input" placeholder="Event Code" />
            <button
              className="landingPage__button"
              type="submit"
              onClick={() => {
                const eventName = document.getElementById("event_code_input").value;
                if (eventName.trim() === '') {
                  setError(true);
                } else {
                  setError(false);
                  navigate("/queue?event_name=" + eventName);
                }
              }}
            >
              Join Event
            </button>
            <hr />
            <button
              className="landingPage__button"
              type="button"
              onClick={handleCreateEventClick}
            >
              Create Event
            </button>
          </form>
          <br /><br />
          <Footer onShowModal={handleShowModal} />
        </div>
      </div>

      {/* Terms of Service & Privacy Policy Modal */}
      {showModal && (
        <div className="modal-overlay" style={overlayStyles}>
          <div className="modal-content" style={modalStyles}>
            <h2 style={{color: "black"}}>Terms of Service &amp; Privacy Policy</h2>
            <div style={{ maxHeight: "400px", overflowY: "auto", margin: "1rem 0" }}>
              <p>
                <strong>Last Updated:</strong> 12/16/2024
              </p>

              <h3 style={{color: "black"}}>TrackTap Terms of Service</h3>
              <p>
                By using TrackTap (“the Service”), you agree to the following terms:
              </p>
              <ol>
                <li>
                  <strong>No Warranties from Spotify:</strong> You acknowledge that we make no warranties or representations on behalf of Spotify. We expressly disclaim all implied warranties with respect to the Spotify Platform, Spotify Service, and Spotify Content, including implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
                </li>
                <li>
                  <strong>No Modifications of Spotify Platform:</strong> You agree not to modify or create derivative works based on the Spotify Platform, Spotify Service, or Spotify Content.
                </li>
                <li>
                  <strong>No Reverse Engineering:</strong> You agree not to decompile, reverse-engineer, disassemble, or otherwise reduce any part of the Spotify Platform, Spotify Service, or Spotify Content to source code or other human-perceivable form, to the extent allowed by law.
                </li>
                <li>
                  <strong>Liability Disclaimer:</strong> TrackTap is provided on an “as is” basis. All use is at your own risk. You agree that TrackTap is responsible for its own services and disclaim any liability on the part of Spotify or other third parties.
                </li>
                <li>
                  <strong>Third-Party Beneficiary:</strong> You acknowledge that Spotify is a third-party beneficiary of these terms and may enforce these terms against you.
                </li>
              </ol>

              <h3>TrackTap Privacy Policy</h3>
              <p>
                We are committed to protecting your privacy. This policy explains how TrackTap collects, uses, and shares information about you.
              </p>
              <ol>
                <li>
                  <strong>Data Collection:</strong> We may collect information such as your Spotify user ID, playlists, and basic profile details when you connect your Spotify account. Additional information may be collected through cookies or similar technologies.
                </li>
                <li>
                  <strong>Use of Information:</strong> We use your data to facilitate playlist selection, voting, and event management. We do not sell your information to third parties.
                </li>
                <li>
                  <strong>Sharing with Third Parties:</strong> We only share data with third parties (e.g., Spotify) as necessary to provide our core functionality.
                </li>
                <li>
                  <strong>Cookie Usage:</strong> We may use cookies to store session data and track usage analytics. Third parties may place cookies on your browser to collect information about your activities over time.
                </li>
                <li>
                  <strong>Cookie Management:</strong> You can configure your browser to reject cookies or notify you before a cookie is stored. Note that disabling cookies may limit some features of the Service.
                </li>
                <li>
                  <strong>Contact Us:</strong> If you have questions regarding your data, please contact us at <em>sheiman.robert.18@gmail.com</em> or the email listed on our site.
                </li>
              </ol>
              <p>
                By clicking “I Accept,” you acknowledge that you have read and agree to both our Terms of Service and this Privacy Policy.
              </p>
            </div>
            <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
              <input
                type="checkbox"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
                id="acceptTerms"
                style={{ marginRight: "0.5rem" }}
              />
              <label htmlFor="acceptTerms">I agree to the Terms of Service and Privacy Policy</label>
            </div>
            <button
              onClick={() => {
                if (termsAccepted) {
                  localStorage.setItem("tracktap_termsAccepted", "true");
                  setShowModal(false);
                  spotifyAuth();
                } else {
                  alert("Please accept the Terms of Service and Privacy Policy to continue.");
                }
              }}
              style={{ marginRight: "1rem" }}
            >
              I Accept
            </button>
            <button onClick={() => setShowModal(false)}>Close</button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default LandingPage;
