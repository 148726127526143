import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Snackbar} from "@material-ui/core";
import { Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import io from "socket.io-client";
import CurrentlyPlaying from "../Components/CurrentlyPlaying/CurrentlyPlaying";
import QueueOptions from "../Components/QueueOptions/QueueOptions";
import SongSuggestion from "../Components/SongSuggestion/SongSuggestion";
import sortAndReturnNumerically, {
  sortAndReturnAlphabetically,
} from "../Helpers/sort";
import FlipMove from 'react-flip-move';
import "./LiveQueue.css";

// Socket IO
const socket = io.connect(`${process.env.REACT_APP_BACKEND_BASE_URL}`);

function LiveQueue() {
  const navigate = useNavigate();

  // TOS Modal control
  const [showModal, setShowModal] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  // Spotify Queue logic
  const [currentSong, setCurrentSong] = useState({});
  const [songsInQueue, setSongsInQueue] = useState([]);
  const [songProgress, setSongProgress] = useState(0);
  const [songDuration, setSongDuration] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);
  const [sortedByRank, setSortedByRank] = useState(true);
  const [toastOpen, setToastOpen] = useState(false);
  const [isErrorState, setIsErrorState] = useState(false);

  const defaultAlbumArtworkURL =
    "https://static.vecteezy.com/system/resources/thumbnails/002/249/673/small/music-note-icon-song-melody-tune-flat-symbol-free-vector.jpg";

  // Read acceptance status from localStorage on mount
  useEffect(() => {
    const storedAccepted = localStorage.getItem("tracktap_termsAccepted");
    if (!storedAccepted) {
      // If not accepted yet, show the modal
      setShowModal(true);
    } else {
      // Otherwise skip showing the modal
      setTermsAccepted(true);
    }
  }, []);

  const handleToastClose = (event, reason) => {
    if (reason === "clickaway") return;
    setToastOpen(false);
  };

  // Extract event_name from query params
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let event_name = params.get("event_name");

  const sortQueue = useCallback(
    (queue) => {
      return sortedByRank
        ? sortAndReturnNumerically(queue)
        : sortAndReturnAlphabetically(queue);
    },
    [sortedByRank]
  );

  const loadPlaylistSongs = useCallback(() => {
    const requestOptions = {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/event_songs?event_name=${event_name}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        const song_list = data.songs.map((song) => ({
          name: song.name,
          artist: song.artist,
          id: song.id,
          votes: song.votes,
          spotify_id: song.spotify_id,
          image_url: song.image_url,
        }));
        setSongsInQueue(song_list); // We'll handle final sorting via useMemo
      })
      .catch((error) => {
        setIsErrorState(true);
      });
  }, [event_name]);

  const onVote = (index, voteChange)  => {
    socket.emit("vote", {
      song: songsInQueue[index].id,
      change: voteChange,
    });
    const updatedQueue = [...songsInQueue];
    updatedQueue[index].votes += voteChange;
    setSongsInQueue(sortQueue(updatedQueue));
  };

  const deleteSuggestion = (index) => {
    const updatedQueue = [...songsInQueue];
    updatedQueue.splice(index, 1);
    setSongsInQueue(sortQueue(updatedQueue));
  };

  const toggleSortType = () => {
    setSortedByRank(!sortedByRank);
  };

  const sortedSongsInQueue = useMemo(() => {
    return sortQueue(songsInQueue);
  }, [songsInQueue, sortQueue]);

  const addSongToQueue = (name, artist, song) => {
    // Implementation placeholder
    return;
  }

  const updateCurrentSong = useCallback(() => {
    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ event_name: event_name }),
    };

    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/currently_playing`,
      requestOptions
    )
      .then((res) => {
        if (res.status !== 200) {
          return null;
        }
        return res.json();
      })
      .then((data) => {
        if (!data) return;
        setSongProgress(data.progress_ms / 1000);
        setSongDuration(data.duration_ms / 1000);
        if (data.id === currentSong.id) return;
        else {
          setCurrentSong({
            name: data.name,
            artist: data.artist,
            albumWorkURL: data.image_url,
            id: data.id,
            spotifyId: data.spotify_id
          });
          localStorage.setItem(data.id.toString(), "0");
        }
      });
  }, [event_name, currentSong.id]);

  useEffect(() => {
    if(isAdmin) {
      setToastOpen(true);
    }
  }, [isAdmin]);

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/status`,
          {
            method: "GET",
            credentials: "include",
          }
        );
        const data = await response.json();
        setIsAdmin(data.eventName === event_name);
      } catch (error) {
        console.error("Error checking authentication status:", error);
      }
    };
    checkAuthStatus();
  }, [navigate, event_name]);

  useEffect(() => {
    updateCurrentSong();
    const intervalId = setInterval(updateCurrentSong, 5000);
    return () => clearInterval(intervalId);
  }, [updateCurrentSong]);

  useEffect(() => {
    loadPlaylistSongs();

    socket.on("send_vote", (newRating) => {
      console.log("Received vote from socket", newRating);
      setSongsInQueue((prevQueue) => {
        const updatedQueue = prevQueue.map((song) => {
          if (song.id === newRating.songId) {
            return {
              ...song,
              votes: newRating.rating,
            };
          }
          return song;
        });
        return sortQueue(updatedQueue);
      });
    });

    return () => {
      socket.off("send_vote");
    };
  }, [loadPlaylistSongs, sortQueue]);

  useEffect(() => {
    if(!currentSong.name) {
      setCurrentSong({
        name: "Spotify playback paused.",
        artist: "Make sure Spotify is playing.",
        albumWorkURL: defaultAlbumArtworkURL,
      });
    }
  }, [currentSong.name]);

  // Handle Accept button
  const handleAcceptTerms = () => {
    if (termsAccepted) {
      localStorage.setItem("tracktap_termsAccepted", "true");
      setShowModal(false);
    } else {
      alert("Please check the box to accept the Terms of Service and Privacy Policy.");
    }
  };

  return (
    <div className="event_page__container">
      <div className="navigation__container">
        <h3 className="navigation__home" onClick={() => navigate("/home")}>
          TrackTap.
        </h3>
        <button
          className="navigation__button"
          onClick={() => navigate("/create_event")}
        >
          Create Event
        </button>
      </div>
      <div className="queue__container">
        <div className="queue__header">
          <CurrentlyPlaying
            name={currentSong.name}
            artist={currentSong.artist}
            spotifyId={currentSong.spotifyId}
            albumWorkURL={currentSong.albumWorkURL}
            eventName={event_name}
            songProgress={songProgress}
            songDuration={songDuration}
          />
        </div>

        <QueueOptions
          sortedByRank={sortedByRank}
          toggleSortType={toggleSortType}
          addSongToQueue={addSongToQueue}
          eventName={event_name}
          songList={songsInQueue}
          isAdmin={isAdmin}
        />

        {!isErrorState ? (
          <div className="song__queue">
            <FlipMove>
              {sortedSongsInQueue.map((song, index) => (
                <SongSuggestion
                  key={`${song.id}`}
                  ref={React.createRef()}
                  name={song.name}
                  id={song.id}
                  spotifyId={song.spotify_id}
                  artist={song.artist}
                  votes={song.votes}
                  imageUrl={song.image_url}
                  index={index}
                  onVote={onVote}
                  eventName={event_name}
                  deleteSuggestion={deleteSuggestion}
                />
              ))}
            </FlipMove>
          </div>
        ) : (
          <div className="error_message">
            <h3>Oops! This event code doesn't seem to be valid.</h3>
            <button onClick={() => navigate("/home")}>
              Go Back
            </button>
          </div>
        )}
        <Snackbar
          open={toastOpen}
          autoHideDuration={8000}
          onClose={handleToastClose}
        >
          <Alert
            onClose={handleToastClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Thanks for using Tracktap! Please clear your queue to begin. Tracktap can only add songs to the back of your queue. Please let songs finish playing; skipping a song prevents Tracktap from queueing the highest voted songs.
          </Alert>
        </Snackbar>
      </div>

      {/* === Terms of Service & Privacy Policy Modal === */}
      {showModal && (
        <div className="modal-overlay" style={overlayStyles}>
          <div className="modal-content" style={modalStyles}>
            <h2>Terms of Service & Privacy Policy</h2>
            <div style={{ maxHeight: "400px", overflowY: "auto", margin: "1rem 0" }}>
              <p>
                <strong>Last Updated:</strong> 12/16/2024
              </p>

              <h3 style={{color: "black"}}>TrackTap Terms of Service</h3>
              <p>
                By using TrackTap (“the Service”), you agree to the following terms:
              </p>
              <ol>
                <li>
                  <strong>No Warranties from Spotify:</strong> You acknowledge that we make no warranties or representations on behalf of Spotify. We expressly disclaim all implied warranties with respect to the Spotify Platform, Spotify Service, and Spotify Content, including implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
                </li>
                <li>
                  <strong>No Modifications of Spotify Platform:</strong> You agree not to modify or create derivative works based on the Spotify Platform, Spotify Service, or Spotify Content.
                </li>
                <li>
                  <strong>No Reverse Engineering:</strong> You agree not to decompile, reverse-engineer, disassemble, or otherwise reduce any part of the Spotify Platform, Spotify Service, or Spotify Content to source code or other human-perceivable form, to the extent allowed by law.
                </li>
                <li>
                  <strong>Liability Disclaimer:</strong> TrackTap is provided on an “as is” basis. All use is at your own risk. You agree that TrackTap is responsible for its own services and disclaim any liability on the part of Spotify or other third parties.
                </li>
                <li>
                  <strong>Third-Party Beneficiary:</strong> You acknowledge that Spotify is a third-party beneficiary of these terms and may enforce these terms against you.
                </li>
              </ol>

              <h3>TrackTap Privacy Policy</h3>
              <p>
                We are committed to protecting your privacy. This policy explains how TrackTap collects, uses, and shares information about you.
              </p>
              <ol>
                <li>
                  <strong>Data Collection:</strong> We may collect information such as your Spotify user ID, playlists, and basic profile details when you connect your Spotify account. Additional information may be collected through cookies or similar technologies.
                </li>
                <li>
                  <strong>Use of Information:</strong> We use your data to facilitate playlist selection, voting, and event management. We do not sell your information to third parties.
                </li>
                <li>
                  <strong>Sharing with Third Parties:</strong> We only share data with third parties (e.g., Spotify) as necessary to provide our core functionality.
                </li>
                <li>
                  <strong>Cookie Usage:</strong> We may use cookies to store session data and track usage analytics. Third parties may place cookies on your browser to collect information about your activities over time.
                </li>
                <li>
                  <strong>Cookie Management:</strong> You can configure your browser to reject cookies or notify you before a cookie is stored. Note that disabling cookies may limit some features of the Service.
                </li>
                <li>
                  <strong>Contact Us:</strong> If you have questions regarding your data, please contact us at <em>sheiman.robert.18@gmail.com</em> or the email listed on our site.
                </li>
              </ol>
              <p>
                By clicking “I Accept,” you acknowledge that you have read and agree to both our Terms of Service and this Privacy Policy.
              </p>
            </div>
            <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
              <input
                type="checkbox"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
                id="acceptTerms"
                style={{ marginRight: "0.5rem" }}
              />
              <label htmlFor="acceptTerms">I agree to the Terms of Service and Privacy Policy</label>
            </div>
            <button
              style={{ marginRight: "1rem" }}
              onClick={handleAcceptTerms}
            >
              I Accept
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default LiveQueue;

// Inline modal styling (for demonstration only; consider moving to CSS)
const overlayStyles = {
  position: "fixed",
  top: 0, left: 0, right: 0, bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.3)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 999
};

const modalStyles = {
  background: "#c8c8dc",
  padding: "10px",
  borderRadius: "8px",
  maxWidth: "600px",
  width: "90%",
  maxHeight: "80vh",
  overflowY: "auto",
  boxShadow: "0 2px 8px rgba(0,0,0,0.3)"
};
